import React, { useRef, useState } from "react";
import clsx from "clsx";
import MUITableRow, {
  TableRowProps as RowProps,
} from "@material-ui/core/TableRow";
import TableCell, { Props as TableCellProps } from "./TableCell";
import { ColumnType } from "./Table";
import Checkbox from "../Checkbox";
import Icon from "../Icon";
import IconButton from "../IconButton";
import Collapse from "../Collapse";
import {
  withStyles,
  makeStyles,
  createStyles,
  styled,
} from "@material-ui/core/styles";
import { ExpandableConfig } from "./Table";
import CopyToClipboardButton from "../CopyToClipboardButton";

export type TableRowProps = RowProps;

export const TableRow = withStyles(() =>
  createStyles({
    root: {
      padding: 10,
    },
  })
)(MUITableRow);

export const ExpandableCell = styled("td")({
  padding: 0,
});

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    openRow: {
      boxSizing: "border-box",
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.level1,
    },
    expandableCell: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.second,
    },
  })
);

interface BodyRowProps {
  rowKey?: string;
  index: number;
  isRowSelection?: boolean;
  columns: ColumnType[];
  data: any;
  selected?: boolean;
  handleSelectRow?: (value: boolean) => void;
  expandable?: ExpandableConfig;
}

const renderDefaultIcon = (expanded?: boolean) =>
  expanded ? <Icon name="ArrowTop" /> : <Icon name="ArrowDown" />;

const BodyRow = ({
  index,
  columns,
  data,
  selected,
  isRowSelection,
  handleSelectRow,
  expandable,
}: BodyRowProps) => {
  const {
    highlightRow = false,
    rowExpandable = () => true,
    expandedRowRender,
    expandIcon = renderDefaultIcon,
  } = expandable || {};
  const [open, setOpen] = useState<boolean | undefined>();
  const classes = useStyles();
  const refEl = useRef(null);
  let expandSolSpan = columns.length;

  if (isRowSelection) {
    expandSolSpan += 1;
  }

  if (expandable) {
    expandSolSpan += 1;
  }

  const handleClick = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    handleSelectRow && handleSelectRow(checked);
  };
  return (
    <>
      <TableRow
        ref={refEl}
        className={clsx({
          [classes.openRow]: highlightRow && open,
        })}
      >
        {expandable && rowExpandable && rowExpandable(data) ? (
          <TableCell width={10} key="row-expandable" align="center">
            <IconButton onClick={() => setOpen(!open)} size="small">
              {expandIcon && expandIcon(!!open)}
            </IconButton>
          </TableCell>
        ) : expandable && rowExpandable && !rowExpandable(data) ? (
          <TableCell width={10} key="row-expandable" align="center" />
        ) : null}
        {isRowSelection && (
          <TableCell width={10} key="row-selection" align="center">
            <Checkbox checked={selected} size="small" onChange={handleClick} />
          </TableCell>
        )}
        {columns &&
          columns.map((c, i) => {
            const { key, render, cellOptions = {} as TableCellProps } = c;
            return (
              <TableCell
                key={i}
                {...cellOptions}
                colSpan={!i && rowExpandable && !rowExpandable(data) ? 2 : 1}
              >
                {render
                  ? render(data[key], data, index, refEl)
                  : key &&
                    (typeof data[key] === "string" ||
                      typeof data[key] === "number") &&
                    data[key]}
                {c.copy && (
                  <CopyToClipboardButton showByHover text={data[key]} />
                )}
              </TableCell>
            );
          })}
      </TableRow>
      {expandedRowRender && rowExpandable && rowExpandable(data) ? (
        <TableRow key={`expandable_${index}`}>
          <ExpandableCell style={{ padding: 0 }} colSpan={expandSolSpan}>
            <Collapse
              className={clsx({
                [classes.expandableCell]: highlightRow,
              })}
              in={open}
            >
              {expandedRowRender(data)}
            </Collapse>
          </ExpandableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default React.memo(BodyRow);
